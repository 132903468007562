<template>
  <div id="features" class="hightlighted-info container">
    <div class="row mb-4">
      <div class="col-12 col-md-10 offset-md-1 text-center">
        <h2 class="title font-family-krona">Our Main Features</h2>
        <p class="subtitle text-gray text-uppercase">Learn about our main features and enjoy many advantages</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-10 offset-md-1">
        <div class="row">
          <div class="col-md-4 col-12 text-center">
            <div class="bg-gray-dark card-gray-dark">
              <span class="text-primary display-4"><font-awesome-icon :icon="['fas', 'chart-line']" /></span>
              <h3 class="mt-3 font-weight-normal font-family-krona">Global balance</h3>
              <p class="text-gray m-0">View the global balance of all your cryptocurrencies. Know your global balance in USDT in real time</p>
            </div>
          </div>  
          <div class="col-md-4 col-12 text-center">
            <div class="card-gray-dark">
              <span class="text-primary display-4"><font-awesome-icon :icon="['fas', 'sync-alt']" /></span>
              <h3 class="mt-3 font-weight-normal font-family-krona">Sync from wallet</h3>
              <p class="text-gray m-0">Directly import cryptocurrencies from your wallet and view their price in real time</p>
            </div>
          </div>  
          <div class="col-md-4 col-12 text-center">
            <div class="bg-gray-dark card-gray-dark">
              <span class="text-primary display-4 font-family-krona"><img height="54" src="@/assets/bitcoin.png" alt="logo bitcoin"/></span>
              <h3 class="mt-3 font-weight-normal">Import your cryptos</h3>
              <p class="text-gray m-0">Look for the symbol or contract address of your cryptocurrencies to import and track them</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'Home',
  props: [
    'src',
    'alt'
  ],
  data() {
    return {
    };
  },
  created() {
    this.$emit('widgetLoaded');
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .hightlighted-info{

    margin-bottom: 148px;

    .title{
      font-size: 28px;
      font-weight: normal;
    }
    .subtitle{
      font-size: 18px;
    }
  }

  .card-gray-dark {
    border-radius:16px;
    padding:1rem;
    
    p{
      font-size: 0.9rem;
    }
    h3{
      font-size: 18px;
    }
  }
</style>
